.pdf_button {
  background-color: red;
}

.view_pdf_button {
  margin-top:15px;
}

.fallback_button,
.fallback_button:hover {
  text-decoration: none;
  margin-top: 20px;
  background-color: transparent;
  border-radius: 100px;
  font-style: normal;
  width: 70%;
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  color: gray;
  border: none;
}
