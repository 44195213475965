.terms_privacy_top_header {
  background-color: #FDF8F0;
}

.page_content_section {
  padding: 80px 0 140px 0;
}

@media screen and (max-width: 760px) {
  .terms_privacy_top_header {
    padding-top: 50px;
    padding-bottom: 10px;
  }

  .top_header_illustration_column {
    visibility: hidden;
    height: 0px;
  }

  .page_content_section {
    padding-bottom: 50px;
  }
}


/* TEXT */

.terms_privacy_top_header h1 {
  font-style: normal;
  font-weight: 900;
  font-size: 400%;
  line-height: 72px;
  color: #30323B;
  margin-top: 0;
  margin-bottom: 20px;
}

.hero_text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  opacity: 0.75;
  width: 75%;
}

@media screen and (max-width: 760px) {
  .terms_privacy_top_header h1 {
    font-size: 50px;
    line-height: 54px;
  }

  .hero_text {
    width: 100%;
  }

}


/* IMAGES */

.top_header_illustration {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.top_header_illustration_reverse_column, .top_header_illustration_reverse {
  visibility: hidden;
  height: 0px;
}

@media screen and (max-width: 760px) {

  .top_header_illustration_reverse_column {
    visibility: visible;
    height: 200px;
  }

  .top_header_illustration_reverse {
    visibility: visible;
    height: 200px;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 70%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

}
