.instruction_page_section {
  padding-top: 15px;
  background-color: #FAFAFB;
}

.instruction_page_section_white_label {
  padding-top: 100px;
  padding-bottom: 70px;
  background-color: #f4f7fb;
}

.instruction_content_row {
  margin-top: 25px;
}

.instruction_steps_row {
  margin-top: 50px;
  min-height: 150px;
}

.back_to_product_breadcrumb {
  margin-left: 25px;
  display: none;
}

.back_to_product_breadcrumb_link,
.back_to_product_breadcrumb_link:hover {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 10px;
  color: #024876;
  text-decoration: none;
  margin-bottom: 25px;
}

.back_to_product_breadcrumb_icon {
  margin-right: 3px;
}

.small_screen_pdf_button {
  display: none;
}

.video_player {
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 15px;
  margin-top: 25px;
}

.instruction_page_company_card {
  background: #FFFFFF;
  border: 1px solid #DBDCE3;
  border-radius: 8px;
}

.step_count_column {
  height: 57px;
  margin-bottom: 25px;
}

.instruction_step_images_row {
  width: 100%;
  margin-left: 0px;
  margin-right: 0px;
}

.instruction_footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 70px;
  color: white;
  text-align: center;
  display: none;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #004876;
  box-shadow: 0 6px 12px 0 #14142b0d;
}

.instruction_options_underline {
  margin-top: 0px;
}

@media screen and (max-width: 760px) {

  .instruction_page_section {
    padding-bottom: 50px;
  }

  .instruction_steps_row {
    margin-top: 20px;
  }

  .button_navigation_row {
    display: none;
  }

  .small_screen_pdf_button {
    display: inherit;
  }

  .hide_when_small {
    display: none;
  }

  .back_to_product_breadcrumb {
    display: flex;
  }

  .instruction_footer {
    display: block;
  }

}


/* IMAGES */

.instruction_page_company_logo {
  width: 80px;
  height: auto;
  margin-top: 10px;
}

.modal_text_instruction_image {
  width: 400px;
  height: 400px;
  margin-bottom: 20px;
}

.text_instruction_image {
  width: 120px;
  height: 120px;
}

.company_card_product_image {
  width: 100%;
  max-height: 250px;
  border-radius: 10px;
  margin-bottom: 50px;
}

.instruction_page_top_content_img_placeholder {
  width: 100%;
}

.instruction_bottom_content_image_placeholder {
  width: 90%;
  max-height: 100px;
  margin-top: 30px;
}

@media screen and (max-width: 760px) {

  .view_all_instructions_row {
    margin-bottom: 50px;
  }

  .text_instruction_image {
    margin-bottom: 10px;
  }

  .modal_text_instruction_image {
    width: 200px;
    height: 200px;
  }
}



/* TEXT */

.instruction_page_section h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 42px;
}

.instruction_page_section h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
  margin-top: 10px;
}

.instruction_page_section_white_label h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 42px;
}

.instruction_page_section_white_label h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
  margin-top: 10px;
}

.instruction_title_mobile {
  display: none;
}

.product_result_category {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: #004876;
  background-color: rgba(0, 72, 118, 0.3);
  border-radius: 100px;
  padding: 4px 10px;
  display:inline-block;
}

.step_count_text {
  width: 100%;
  background: #ffffff;
  box-shadow: 0 6px 12px 0 #14142b0d;
  border-radius: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
}

.step_count_text_shorthand {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  text-align: right;
  color: #004876;
  float: right;
  padding-right: 20px;
}

.instruction_page_product_description {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 144%;
  letter-spacing: 0.1px;
  color: #696974;
}

.text_row {
  transition: 300ms all ease;
  opacity: 0;
  will-change: opacity;
}

.show_row {
  opacity: 1;
}

.instruction_step_text {
  min-height: 100px;
  margin-top: 20px;
  padding-left: 8px;
  padding-right: 8px;
  font-family: 'Red Hat Display', sans-serif;
  color: #30323B;
  font-style: normal;
  font-weight: normal;
  font-size: 16px !important;
}

.instruction_step_text span {
  background-color: #f4f7fb !important;
  font-family: 'Red Hat Display', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px !important;
  line-height: 150%;
  color: #30323B;
}

@media screen and (max-width: 760px) {
  .instruction_page_section h1 {
    font-size: 24px;
    line-height: 42px;
    padding-left: 16px;
  }

  .instruction_page_section_white_label {
    padding-top: 20px;
  }

  .instruction_page_section_white_label h1 {
    font-size: 35px;
    line-height: 42px;
    padding-left: 16px;
  }

  .instruction_title_mobile {
    /* display: block; */
  }

  .instruction_step_text {
    font-size: 12px;
    line-height: 150%;
    height: auto;
    overflow: none;
  }
}

.list_group_title {
  display: inline!important;
}

.list_group_value {
  float: right;
  font-size: 18px;
}

.list_group_value_form_group {
  margin-bottom: 0;
}

.instruction_info_list_item {
  color: #868789;
}

/* BUTTONS */


.visit_product_link:link,
.visit_product_link:visited {
  text-decoration: none;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #004876;
  display: block;
}

.instruction_option_link,
.instruction_option_link:hover {
  margin-right: 20px;
  margin-bottom: 0px;
  padding-bottom: 10px;
  text-decoration: none;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: #C4C4C4;
  border: none;
  background: none;
}

.instruction_option_link:focus {outline:0;}

.video_instruction_option_link {
  padding-bottom: 8px;
}

.instruction_option_active,
.instruction_option_active:hover {
  border-bottom: 3px solid #004876;
  color: #004876;
}

.image_button {
  text-decoration: none;
  border: none;
  background: none;
  transition: all 0.3s;
  cursor: pointer;
}

.image_button:hover {
  transform: scale(1.1);
}

.image_button:active {
  transform: scale(0.95);
}

.image_button:focus {outline:0;}

.enlarged_image_button {
  text-decoration: none;
  border: none;
  background: none;
  transition: all 0.3s;
  cursor: pointer;
}

.enlarged_image_button:hover {
  transform: scale(1.03);
}

.enlarged_image_button:active {
  transform: scale(0.95);
}

.enlarged_image_button:focus {outline:0;}

.modal_image_button,
.modal_image_button:hover {
  text-decoration: none;
  background: #004876;
  border-radius: 100px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  color: #FFFFFF;
  padding: 8px 30px;
  border: none;
}

.modal_image_button:focus {outline:0;}

.arrow_icon {
  color: #004876;
  font-size: 40px;
}

.instruction_options_icon {
  color: #ffffff;
  font-size: 25px;
  margin-top: 5px;
}

.instruction_info_icon {
  margin-right: 5px;
  font-size: 15px;
}

.back_to_product_link:link,
.back_to_product_link:visited {
  text-decoration: none;
  color: #fff;
  background: #004876;
  border: none;
  border-radius: 100px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 14px;
  padding-right: 14px;
}

.instruction_options_button {
  margin: 0 auto;
  display: block;
}


@media screen and (max-width: 760px) {
  .instruction_option_link {
    font-size: 14px !important;
  }

  .list_group_title {
    display: none!important;
  }

  .list_group_value {
    float: none;
    margin-left: 5px;
  }

  .arrow_icon {
    color: #ffffff;
    font-size: 35px;
  }

  .image_button {
    margin-top: 15px;
  }

  #video_instruction_id {
    float: right;
    padding-top: 6px!important;
    padding-bottom: 10px!important;
    margin-bottom:  0px!important;
    margin-right: 0px;
  }
}

@media screen and (max-width: 370px) {
  .list_group_value {
    font-size: 10px;
  }
}


/* IMAGE GRID LAYOUT */

.image_grid_row {
  display: flex;
  flex-wrap: wrap;
  padding: 0 4px;
}

/* Create four equal columns that sits next to each other */
.image_grid_column {
  /* ONLY USING ONE COLUMN FOR NOW SO COMMENTING THESE OUT JUST INCASE WE REVERT */
  /* flex: 50%;
  max-width: 50%; */

  flex: 100%;
  max-width: 100%;  
  padding: 0 4px;
}

.image_grid_column img {
  margin-top: 8px;
  vertical-align: middle;
  border-radius: 15px;
  width: 100%;
}

/* Responsive layout - makes a two column-layout instead of four columns */
/* @media screen and (max-width: 800px) {
  .image_grid_column {
    flex: 50%;
    max-width: 50%;
  }
} */

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
/* @media screen and (max-width: 600px) {
  .image_grid_column {
    flex: 100%;
    max-width: 100%;
  }
} */