.product_profile_section {
  padding-top: 15px;
  background-color: #FAFAFB;
}

.product_profile_section h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 42px;
}

.product_profile_section hr {
  background: #DBDCE3;
  opacity: 0.5;
  height: 1px;
  width: 100%;
  color: #DBDCE3;
  margin-left: 20px;
}

.product_profile_section_white_label {
  padding-top: 100px;
  margin-top: 20px;
  padding-bottom: 70px;
  background-color: #f4f7fb;
}

.product_profile_section_white_label h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 42px;
}

.product_profile_section_white_label hr {
  background: #DBDCE3;
  opacity: 0.5;
  height: 1px;
  width: 100%;
  color: #DBDCE3;
  margin-left: 20px;
}

.product_profile_card {
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #DBDCE3;
  border-radius: 8px;
}

.instruction_list_card {
  border: 1px solid #DBDCE3;
  border-radius: 8px;
}

.instructions_row {
  margin-top: 40px;
}

.instruction_section_row {
  margin-top: 15px;
}

.small_image_row {
  margin-top: 8px;
}

.instruction_section_count_badge {
  margin-left: 4px;
  background-color: rgba(255, 144, 21, 0.12);;
  border-radius: 100px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  color: #FF9015;
}

.instructions_container {
  width: 100%;
  margin-left: 0px;
  margin-right: 0px;
}

.instruction_row {
  width: 100%;
}

@media screen and (max-width: 760px) {
  .product_profile_section {
    padding-bottom: 40px;
  }

  .product_main_image {
    margin-bottom: 15px;
  }

  .small_image_row {
    margin-bottom: 20px;
    display: none;
  }
}


/* IMAGES */

.angle_icon {
  float: right;
}

.product_bottom_content_image_placeholder {
  width: 90%;
  max-height: 100px;
  margin-top: 30px;
}

.product_smaller_image {
  width: 50px;
  height: 50px;
  border-radius: 8px;
}

.product_profile_company_logo {
  width: 100px;
  height: auto;
}

.product_main_image {
  width: 100%;
  border-radius: 8px;
  height: auto;
}

.product_top_content_placeholder {
  width: 90%;
  max-height: 400px;
}

@media screen and (max-width: 760px) {
  .product_top_content_placeholder {
    width: 100%;
  }
}


/* TEXT */

.instruction_title {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 14px;
  display: inline;
  /* margin-left: 25px; */
}

.instruction_section_button_text {
  float: left;
}

.product_result_category {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: #004876;
  background-color: rgba(0, 72, 118, 0.3);
  border-radius: 100px;
  padding: 4px 10px;
  display:inline-block;
}

.product_profile_description {
  /* height: 100px;
  overflow: scroll; */
  text-align: justify;
}

.count_number {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
}

.count_number_text {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1px;
  color: #696974;
}

.instruction_card_description {
  height: 120px;
  overflow: scroll;
}

.instruction_info_list_item {
  color: #868789;
}

.list_group_value {
  float: right;
}

.instructions_title {
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 29px;
  margin-bottom: 0px;
  padding:10px;
}

.supplemental_directive_text {
  margin-top: 25px;
  min-height: 80px;
}

@media screen and (max-width: 760px) {
  .instruction_title {
    display: block;
  }

  .instruction_card_description {
    display: none;
  }
}


/* BUTTONS */

.visit_product_link:link,
.visit_product_link:visited {
  text-decoration: none;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #004876;
  padding-bottom: 10px;
  display: block;
}


.view_instruction_link {
  /* float: right; */
  margin-left: 30px;
  margin-top: 5px;
  display: inline;
}

.view_instruction_link:link,
.view_instruction_link:visited {
  text-decoration: none;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: inherit;
  /* float: right; */
}

.instruction_type_not_available:link,
.instruction_type_not_available:visited {
  color: #C4C4C4;
  pointer-events: none;
  cursor: default;
}

.instruction_section_button {
  height: 70px;
  width: 100%;
  color: #004876;
  background-color: #EBF0F4;
  padding-left: 25px;
  padding-right: 25px;
  margin-bottom: 20px;
  border-radius: 10px;
}

.image_button,
.image_button:hover {
  text-decoration: none;
  border: none;
  background: none;
}

.image_button:focus {outline:0;}

.modal_text_instruction_image {
  width: 400px;
  height: 400px;
  margin-bottom: 20px;
}

.modal_image_button,
.modal_image_button:hover {
  text-decoration: none;
  background: #004876;
  border-radius: 100px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  color: #FFFFFF;
  padding: 8px 30px;
  border: none;
}

.instruction_info_icon {
  margin-right: 5px;
  font-size: 15px;
}

.modal_image_button:focus {outline:0;}

@media screen and (max-width: 760px) {
  #text_instruction_link {
    /* float: left; */
  }

  .view_instruction_link {
    font-size: 10px !important;
  }

  .modal_text_instruction_image {
    width: 200px;
    height: 200px;
  }
}
