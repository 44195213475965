/* modal.module.css */

.modal__wrap {
    position: fixed;
    display: block;
    /* overflow-y: scroll; */
    overflow-x: scroll;
    /* display: flex; */
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1040;  /* this number needs to be higher than the fixed-header navbar */
    overflow-x: hidden;
    background-color: rgba(31, 32, 41, .75);
    pointer-events: none;
    opacity: 0;
    transition: opacity 250ms 700ms ease;
}

.visible {
    pointer-events: auto;
    opacity: 1;
    transition: all 300ms ease-in-out;
}

.modal_subwrapper {
  width: 100%;
  height: 100%;
  padding-top: 15px;
  overflow-y: scroll;
  overflow-x: scroll;
}

.modal {
    position: relative;
    display: block;
    margin: 0 auto;
    margin-bottom: 20px;
    border-radius: 4px;
    padding-bottom: 20px;
    background-color: none;
    opacity: 0;
    transform: scale(0.6);
    transition: opacity 250ms 250ms ease, transform 300ms 250ms ease;
    transform: scale(0);
}

.visible .modal {
    opacity: 1;
    transform: scale(1);
    transition: opacity 250ms 500ms ease, transform 350ms 500ms ease;
}

.pdf_nav_header {
  position: relative;
  z-index: 1041;
  background-color: white;
  box-shadow: 0 12px 25px 0 rgba(199, 175, 189, .30);
}

.pdf_nav_item {
  margin-bottom: 0;
}

.pdf_nav_arrow_button {
  text-decoration: none;
  padding-top: 12px;
  padding-bottom: 0px;
  border: none;
  background: none;
  transition: all 0.3s;
  cursor: pointer;
}

.pdf_nav_arrow_button:hover {
  transform: scale(1.1);
}

.pdf_nav_arrow_button:active {
  transform: scale(0.95);
}

.pdf_nav_arrow_button:focus {outline:0;}

.nav_icon {
  color: #004876;
  font-size: 25px;
}

.custom_pdf_page {
  width: 100%;
  margin: 0 auto;
}

.pdf_page_count {
  color: black !important;
  font-size: 18px;
}

.left_list {
  margin-left: 15px;
}

.right_list {
  margin-right: 15px;
}

@media screen and (max-width: 370px) {

  .left_list {
    margin-left: 8px;
  }

  .right_list {
    margin-right: 8px;
  }

  .pdf_page_count {
    font-size: 12px;
  }

  .nav_icon {
    font-size: 18px;
  }

  .pdf_nav_arrow_button {
    padding-top: 10px;
  }
}
