.search_page_header {
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.08);
  position:relative;
  z-index:10;
}

.company_directory_page_header {
  background-color: #FDF8F0;
}

.company_page_header {
  background-color: #F8F9FA;
}

.company_page_nav {
  background-color: #fff;
}

.company_logo {
  height: 60px;
  width: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.product_page_header {
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.08);
  position:relative;
  z-index:10;
}

.instruction_page_header {
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.08);
  position:relative;
  z-index:10;
}

@media screen and (max-width: 760px) {

  .company_header_for_instruction {
    display: none;
  }

}
