.not_found_container h1 {
  color: #fff;
  font-weight: bold;
  font-family: 'Fredoka One', cursive;
}

.not_found_container p {
  color: #fff;
  font-family: 'Fredoka One', cursive;
}

.not_found_container form {
  width: 70%;
  margin: 0 auto;
}

.not_found_container hr {
  width: 85%;
  margin-bottom: 30px;
}

.semnar_logo {
    margin-top: 100px;
    margin-bottom: 75px;
    height: 80px;
}

.register_login_card {
  width: 95%;
  max-width: 460px;
  margin: 0 auto;
  border-radius: 8px;
  margin-bottom: 30px;
}

.not_found_image {
  width: 100%;
  height: auto;
}

/* TEXT  */

.register_login_card_title {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #004876;
  margin-bottom: 25px;
}

.switch_form_text {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #6D768D;
}

/* BUTTONS */

.forgot_pasword_link {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #004876;
  opacity: 0.75;
  margin-top: 6px;
  margin-bottom: 15px;
  float: left;
}

.switch_form_link:link,
.switch_form_link:visited {
  text-decoration: none;
  color: #E04403;
}
