.search_page_header {
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.08);
  position:relative;
  z-index:10;
}

.company_directory_page_header {
  background-color: #FDF8F0;
}

.company_page_header {
  background-color: #FDF8F0;
}

.product_page_header {
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.08);
  position:relative;
  z-index:10;
}

.instruction_page_header {
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.08);
  position:relative;
  z-index:10;
}
