.survey_view_card {
  border-style: solid;
  border-width: 1px;
  border-top-color: #eff0f6;
  border-right-color: #eff0f6;
  border-bottom-color: #eff0f6;
  border-left-color: #eff0f6;
  border-radius: 20px;
  background-color: #ffffff;
  box-shadow: 0 6px 12px 0 #14142b0d;
  width: 50%;
  min-width: 400px;
  margin: auto;
  margin-top: 20px;
  padding-bottom: 0px;
}

.survey_view_card.default {
    padding: 5px 5px;
}

.survey_view_card_title {
  font-size: 30px!important;
  color: #024876;
}

.survey_view_card_text {
  margin-top: 20px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 0px;
  font-family: 'Red Hat Display', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px !important;
  line-height: 150%;
  color: #30323B;
}

.survey_view_card_complete_text {
  margin-top: 20px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 0px;
  font-family: 'Red Hat Display', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px !important;
  line-height: 150%;
  color: #30323B;
}

@media screen and (max-width: 760px) {

  .survey_view_card {
    min-width: 90%;
  }

  .survey_view_card_text {
    line-height: 115%;
  }

}
