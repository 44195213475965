.safety_message_card {
  border-style: solid;
  border-width: 1px;
  border-top-color: #eff0f6;
  border-right-color: #eff0f6;
  border-bottom-color: #eff0f6;
  border-left-color: #eff0f6;
  border-radius: 20px;
  background-color: #ffffff;
  box-shadow: 0 6px 12px 0 #14142b0d;
  width: 50%;
  min-width: 400px;
  margin: auto;
  margin-top: 20px;
  padding-bottom: 0px;
}

.safety_message_card.default {
    padding: 5px 5px;
}

.safety_message_icon_card {
  border-style: solid;
  border-width: 1px;
  border-top-color: #eff0f6;
  border-right-color: #eff0f6;
  border-bottom-color: #eff0f6;
  border-left-color: #eff0f6;
  border-radius: 12px;
  box-shadow: 0 6px 12px 0 #14142b0d;
  width: fit-content;
  padding: 5px 5px;
  padding-bottom: 0px;
  padding-top: 0px;
  margin: auto;
}

.safety_message_icon_card.danger {
  background-color: red;
  transform: scale(1);
  animation: pulse-danger 2s infinite;
}

.safety_message_icon_card.warning {
  background-color: orange;
  transform: scale(1);
  animation: pulse-warning 2s infinite;
}

.safety_message_icon_card.caution {
  background-color: yellow;
  transform: scale(1);
  animation: pulse-caution 2s infinite;
}

.safety_message_icon_card.notice {
  background-color: blue;
  transform: scale(1);
  animation: pulse-notice 2s infinite;
}

@keyframes pulse-danger {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
  }
}

@keyframes pulse-warning {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 144, 22, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 144, 22, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 144, 22, 0);
  }
}

@keyframes pulse-caution {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(247, 228, 104, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(247, 228, 104, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(247, 228, 104, 0);
  }
}

@keyframes pulse-notice {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(25, 87, 147, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(25, 87, 147, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(25, 87, 147, 0);
  }
}

.safety_message_icon_text {
  padding: 5px 5px;
  margin:  5px 5px;
  font-size: 30px;
}

.safety_message_card_body {
  padding-bottom: 0px;
}

.safety_message_icon_text.danger {
  color: #ffffff;
}

.safety_message_icon_text.warning {
  color: black;
}

.safety_message_icon_text.caution {
  color: black;
}

.safety_message_icon_text.notice {
  color: #ffffff;
}

.safety_message_icon {
  margin-right: 3px;
}


.safety_message_text {
  margin-top: 20px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 0px;
  font-family: 'Red Hat Display', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px !important;
  line-height: 150%;
  color: #30323B;
}

@media screen and (max-width: 760px) {

  .safety_message_card {
    min-width: 90%;
  }

  .safety_message_text {
    line-height: 115%;
  }

}
